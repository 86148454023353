.header_hirring-banner {
  background: #fcd3af;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 0 16px;
  display: flex;
}

.header_hirring-banner .hirring-banner {
  cursor: pointer;
  color: #131315;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.header_hirring-banner .hirring-banner .hiring_description {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.header_hirring-banner .hirring-banner .hiring_description .arrow-right-long {
  margin-left: 8px;
}

.header_nav {
  background: #1d1f24;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header_nav .navigation {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  position: relative;
}

.header_nav .navigation .logo {
  height: 84px;
}

.header_nav .navigation .navigation_list {
  z-index: 13;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  display: flex;
  position: relative;
}

.header_nav .navigation .navigation_list .navigation_item {
  cursor: pointer;
  margin-right: 32px;
  display: block;
}

.header_nav .navigation .navigation_list .navigation_item .nav__link {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  position: relative;
}

.header_nav .navigation .navigation_list .navigation_item .nav__link:hover {
  color: #fcd3af;
}

.header_nav .navigation .navigation_list .navigation_item .nav__link:hover .chevron-down-icon {
  filter: invert(51%) sepia(5%) saturate(4000%) hue-rotate(325deg) brightness(195%) contrast(98%);
}

.header_nav .navigation .navigation_list .navigation_item .nav__link.active {
  color: #fcd3af;
}

.header_nav .navigation .navigation_list .navigation_item .nav__link.active:after {
  content: "";
  width: 100%;
  height: 12px;
  background-image: url("underline.f1bcc011.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 12px;
  display: block;
  position: absolute;
  bottom: -8px;
}

.header_nav .navigation .navigation_list .navigation_item:last-of-type {
  margin-right: 48px;
}

.bars {
  display: none;
}

@media (max-width: 1199.98px) {
  .bars {
    display: initial;
  }

  .header_nav .navigation .navigation_list {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .hirring-banner {
    flex-wrap: wrap;
  }

  .hirring-banner .hiring_description {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .hiring_description > div:first-of-type {
    display: none;
  }

  .hirring-banner {
    flex-wrap: initial;
  }
}

@media (max-width: 575.98px) {
  .header_nav .navigation {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .header_nav .navigation .logo {
    height: 60px;
  }
}

.chevron-icon {
  height: 14px;
  width: 12px;
  background: url("chevron-down.ad89efbd.svg");
  display: inline-block;
}

/*# sourceMappingURL=index.2766030a.css.map */

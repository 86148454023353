@import 'common/variables.scss';
@import 'common/mixins.scss';

.header_hirring-banner {
  background: $primary;
  padding: 17px 0 16px;
  @include flex-row(center, center);

  .hirring-banner {
    @include flex-row(null, center);
    cursor: pointer;
    text-decoration: none;
    color: $text-color-dark;

    .hiring_description {
      @include flex-row(null, center);

      .arrow-right-long {
        margin-left: 8px;
      }
    }
  }
}

/*navigation*/

.header_nav {
  @include flex-row(center, center);
  background: $bg;

  .navigation {
    padding-top: 48px;
    padding-bottom: 48px;
    width: 100%;
    position: relative;

    .logo {
      height: 84px;
    }

    @include flex-row(space-between, center);

    .navigation_list {
      padding-top: 12px;
      @include flex-row(null, center);
      position: relative;
      z-index: 13;

      .navigation_item {
        display: block;
        margin-right: 32px;
        cursor: pointer;

        .nav__link {
          color: $text-color;
          text-decoration: none;
          font-weight: $medium-font-weight;
          position: relative;

          &:hover {
            color: $primary;

            .chevron-down-icon {
              filter: invert(51%) sepia(5%) saturate(4000%) hue-rotate(325deg) brightness(195%) contrast(98%);
            }
          }

          &.active {
            color: $primary;

            &:after {
              display: block;
              content: '';
              background-image: url('../assets/underline.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: 100% 12px;
              width: 100%;
              height: 12px;
              position: absolute;
              bottom: -8px;
            }
          }
        }
      }

      .navigation_item:last-of-type {
        margin-right: 48px;
      }
    }
  }
}

.bars {
  display: none;
}

/*breakpoints*/
@media (max-width: 1199.98px) {
  .bars {
    display: initial;
  }

  .header_nav .navigation .navigation_list {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .hirring-banner {
    flex-wrap: wrap;

    .hiring_description {
      width: 100%;
    }
  }
}

@media (max-width: 767.98px) {
  .hiring_description > div:first-of-type {
    display: none;
  }

  .hirring-banner {
    flex-wrap: initial;
  }
}

@media (max-width: 575.98px) {
  .header_nav .navigation {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .header_nav .navigation .logo {
    height: 60px;
  }
}


.chevron-icon {
  background: url(../assets/chevron-down.svg);
  height: 14px;
  width: 12px;
  display: inline-block;
}


